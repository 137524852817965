<template>
  <div class="page-wrap">
    <div class="gas-info-page">
      <div class="gas-info-wrap">
        <div class="gas-info">用户名：{{ gasInfo.username }}</div>
        <div class="gas-info">剩余流量：{{ gasInfo.availableTraffic }}</div>
        <div class="gas-info">流量到期时间：{{ formatToYMDHMS(gasInfo.expiredAt) }}</div>
      </div>
      <div class="official-account">
        <div class="qr">
          <img src="../../assets/official.png" />
          <div class="tip">微信扫码（长按保存识别）</div>
        </div>
        <div class="follow-detail">
          <div class="follow-prompt">关注微信公众号</div>
          <div class="info">每日更新游戏优惠信息</div>
          <div class="info">联系客服更方便</div>
          <div class="info">最新游戏资讯</div>
          <div class="info">switch 玩家交流群</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { getAcceleratorInfo } from "@/api/accelerator/index"
import { reportError } from "@/utils/log"
import { utils } from "@/utils"

export default {
  name: "Home",
  data() {
    return {
      orders: [],
      gasInfo: {
        username: "",
        availableTraffic: "",
        expiredAt: "",
      },
    }
  },
  components: {},
  methods: {
    formatToYMDHMS (date) {
      return utils.formatToYMDHMS(date)
    },
    async getAcceleratorInfo() {
      try {
        let res = await getAcceleratorInfo({
          username: this.gasInfo.username,
        })
        if (res.data.code == 0) {
          this.gasInfo.availableTraffic = res.data.info.availableTraffic
          this.gasInfo.expiredAt = res.data.info.expiredAt
        }
      } catch (error) {
        reportError(error)
      }
    },
  },
  created() {
    if (this.$route.query.username) {
      this.gasInfo.username = this.$route.query.username
      this.getAcceleratorInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.page-wrap {
  width: 7.5rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  .gas-info-page {
    width: 7.18rem;
    margin-top: 0.12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    .gas-info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0.12rem;
      width: 6.54rem;
      background: #ffffff;
      box-shadow: 0px 0.04rem 0.08rem 0px rgba(0, 0, 0, 0.09);
      padding: 0.24rem;
      .gas-info {
        font-size: 0.28rem;
        margin-bottom: 0.12rem;
        .gas-availableTraffic {
          color: #53cda9;
          margin-top: 0.12rem;
          cursor: pointer;
        }
      }
    }
    .official-account {
      margin-top: 0.36rem;
      font-size: 0.28rem;
      width: 6.54rem;
      display: flex;
      justify-content: space-between;
      .qr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 2.94rem;
          height: 2.94rem;
        }
        .tip {
          font-size: 0.24rem;
          color: #717171;
        }
      }
      .follow-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .follow-prompt {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.28rem;
          height: 0.5rem;
          color: #ffffff;
          font-size: 0.26rem;
          background: #53cda9;
          border-radius: 0.06rem;
        }
      }
    }
  }
}
</style>
